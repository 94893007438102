// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
// import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
// ActiveStorage.start()

$(document).ready(function () {
  /** display flash notice **/
  if ($('#flash-notice').text().length != 0) {
    Swal.fire({
      title: 'Success!',
      text: $('#flash-notice').text(),
      icon: 'success',
      confirmButtonText: 'Close',
    })
    $('#flash-notice').text('');
  }

  /** display flash alert **/
  if ($('#flash-alert').text().length != 0) {
    Swal.fire({
      title: 'Error!',
      text: $('#flash-alert').text(),
      icon: 'error',
      confirmButtonText: 'Close',
      target: 'notice-dialog'
    })
    $('#flash-alert').text('');
  }

  $('.datepicker').daterangepicker({
    autoUpdateInput: false,
    locale: {format: 'YYYY-MM-DD'},
    singleDatePicker: true,
    timePicker: false,
    timePicker24Hour: true
  })

  $('.datepicker').on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format('YYYY-MM-DD'));
  });

  $('.datetimepicker').daterangepicker({
    autoUpdateInput: false,
    locale: {format: 'YYYY-MM-DD HH:mm'},
    singleDatePicker: true,
    timePicker: true,
    timePicker24Hour: true
  })

  $('.datetimepicker').on('apply.daterangepicker', function(ev, picker) {
    $(this).val(picker.startDate.format('YYYY-MM-DD HH:mm'));
  });

  $('.timepicker').datetimepicker({
    format: 'HH:mm'
  })

  $('.select2').select2()

  /** File選択時のプレビュー表示 **/
  $('.file-select').change(function () {
    let input = $(this)[0]

    if (input.files && input.files[0]) {
      let previewTag = $(this).parents('.image-block').find('.preview')
      let reader = new FileReader()
      reader.onload = (e) => {
        previewTag.attr('src', e.target.result);
        previewTag.hide();
        previewTag.fadeIn(500);
      }

      reader.readAsDataURL(input.files[0])
    }
  })

});
